'use client'; // Error components must be Client Components

import { useEffect, useState } from 'react';
import style from './error.module.css';
import logger from '@/packages/logger';
interface ErrorProps {
    error: Error & { digest?: string };
}

export default function Error({ error }: ErrorProps) {
    const [state, setState] = useState(<></>);

    useEffect(() => {
        async function loadErrorPage() {
            setState(
                <>
                    <iframe src={`/app-error`} className={style.iframe} />
                </>,
            );
        }

        loadErrorPage();

        logger.error(error);
    }, [error]);

    return state;
}
